<template>
  <div id="stolen">
    <div class="columns">
      <div class="column">
        <p class="title has-text-grey-light is-6">Stolen status</p>
      </div>
      <div class="column is-narrow">
        <p class="title is-6">
          <span class="has-text-danger" v-if="stolen"
            >This vehicle is reported stolen</span
          >
          <span class="has-text-success" v-else>Nothing reported</span>
        </p>
      </div>
    </div>
    <div v-if="hasStolenVehicleData" class="box">
      <div class="field">
        <p class="has-text-grey">Date reported stolen</p>
        <p>{{ formatDate(data.stolenVehicleDetails.creationdate) }}</p>
      </div>
      <div class="field">
        <p class="has-text-grey">Status</p>
        <p>{{ data.stolenVehicleDetails.status }}</p>
      </div>
      <div class="field">
        <p class="has-text-grey">Police force</p>
        <p>{{ data.stolenVehicleDetails.force }}</p>
      </div>
      <div class="field">
        <p class="has-text-grey">Contact number</p>
        <p>{{ data.stolenVehicleDetails.contactNumber }}</p>
      </div>
    </div>
    <div v-if="extraStolenData.status">
      <div
        class="box"
        v-for="(item, index) in extraStolenData.data"
        :key="index"
      >
        <condition-item :item="item" />
      </div>
    </div>
  </div>
</template>

<script>
import { formatDate } from '@/utils/date.utils.ts'
export default {
  name: 'AutoCheckStolen',
  components: {
    conditionItem: () => import('./AutoCheckConditionItem')
  },
  props: {
    data: {
      type: Object,
      required: true
    }
  },
  computed: {
    extraStolenData() {
      return this.data.stolen
    },
    stolen() {
      return this.hasStolenVehicleData || this.data.stolen.status
    },
    hasStolenVehicleData() {
      return this.data.stolenVehicleDetails.status
    }
  },
  methods: {
    formatDate
  }
}
</script>
